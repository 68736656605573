import Vue from 'vue'
import Vant, { Locale } from 'vant'
import 'vant/lib/index.less'
import '../vant-variables.less'
import jaJP from 'vant/es/locale/lang/ja-JP';
import zhCN from "vant/es/locale/lang/zh-CN";
import enUS from "vant/es/locale/lang/en-US";
import koKR from "vant/es/locale/lang/en-US";

Locale.use('ja-JP', jaJP);

Vue.use(Vant)

export default function locales(a) {
 if(a == 'EN'){
   Locale.use('en-US',enUS)
 }
 if(a == 'CH'){
   Locale.use('zh-CN',zhCN)
 }
 if(a=='KO'){
   Locale.use('ko-KR',koKR)
 }
 if(a=='JP'){
   Locale.use('ja-JP',jaJP)
 }
}
