import Vue from 'vue'
import VueI18n from 'vue-i18n'

// 引入语言包
import customZH from './locale/zh'
import customEN from './locale/en'
import customJP from './locale/jp'
import customKO from './locale/ko'

Vue.use(VueI18n)

// 准备翻译的语言环境信息
const messages = {
  JP: {
    ...customJP // 将自定义英文包加入
  },
  CH: {
    ...customZH // 将自定义英文包加入
  },
  EN: {
    ...customEN // 将自定义英文包加入
  },
  KO: {
    ...customKO // 将自定义英文包加入
  },
}

// 通过选项创建 VueI18n 实例
const i18n = new VueI18n({
  locale: 'JP', // 设置地区
  messages
})

export default i18n
