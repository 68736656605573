import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
		path: '/',
		redirect: '/index'
	},
	{
		path: '/index',
		component: () => import('../goods.vue'),
    meta: {
      title: 'SmartWeモバイルオーダー'
    }
  },
  {
		path: '/orderlist',
		component: () => import('../pages/orderlist.vue'),
    meta: {
      title: '注文履歴'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
