export default {
  common:{
    back:"戻る"
  },
  memu:{
    sellout:"完売",
    qtyBounds:"杯限定",
    orderlist:"履歴・会計",
    ordernum:"点数",
    orderhistory:"注文履歴",
    pay:"お会計",
    seecart:"注文リストを確認",
    cartnull:"現在カートに料理はありません",
    confirmorder:"カート内のすべての商品を注文確認しますか？",
    ordersuccess:"ご注文ありがとうございます、料理を出すまでしばらくお待ちください。",
    clearcart:'カート内のすべての商品を削除しますか？',
    cartfoods:"カート内の商品",
    clearbtn:"全て削除",
    stockleft:"残り",
    leftnumber:"杯",
    cartTotal:"点の注文合計金額",
    confirmcart:"点の注文を確定",
    qrtitle:"お会計用QRコード",
    qrmsg:"こちらのQRコードをレジに提示してください",
    deleteFood:'商品を削除しますか？',
    addincart:"カートに追加",
    inventoryShortage:"在庫不足",
    checktotalnum:"、選択した数量とカート内の数量を確認してください",
    changelanhint:"言語を切り替えてもよろしいですか。メニューが再読み込みされ、カートの商品がクリアされます。",
    totalAmount:"合計金額",
    ordertime:"注文日",
    notchoosed:"未選択",
    atleastmae:"少なくとも",
    atleastn: "つをお選びください",
    havepaid:"ページの有効期限が切れました。再度利用する場合はカメラを開いてQRコードを読み取り直してください。",
    noparams:"パラメータが間違っています。再度利用する場合はカメラを開いてQRコードを読み取り直してください。",
    nodata: "データなし",
    zeilitsu:"内消費税"
  }
}
