export default {
  common:{
    back:"뒤로",
  },
  memu:{
    sellout:"매진",
    qtyBounds:"개한정판매",
    orderlist:"이력·회계",
    ordernum:"총 수",
    orderhistory:"주문 내역",
    pay:"회계",
    seecart:"장바구니 확인",
    cartnull:"장바구니에 요리가 없습니다",
    confirmorder:"장바구니의 모든 상품을 주문 확인하시겠습니까?",
    ordersuccess:"주문해 주셔서 감사합니다. 요리를 나올 때까지 기다려주십시오.",
    clearcart:'장바구니의 모든 상품을 삭제하시겠습니까?',
    cartfoods:"장바구니의 상품",
    clearbtn:"모두 삭제",
    stockleft:"남은",
    leftnumber:"개",
    cartTotal:"점 주문 총 금액",
    confirmcart:"점 주문 확정",
    qrtitle:"회계 QR 코드",
    qrmsg:"이 QR 코드를 계산대에 제시하십시오.",
    deleteFood:'상품을 삭제하시겠습니까?',
    addincart:"카드에 추가",
    inventoryShortage:"재고 부족",
    checktotalnum:"、선택한 수량과 장바구니의 수량을 확인하십시오.",
    changelanhint:"언어를 전환하시겠습니까? 장바구니가 비워지고 메뉴가 다시 로드됩니다.",
    totalAmount:"총 금액",
    ordertime:"주문 시간",
    notchoosed:"선택되지 않은",
    atleastmae:"옵션을",
    atleastn: "개 이상 선택하세요",
    havepaid:"페이지의 유효기간이 만료되었습니다. 다시 이용하려면 카메라를 열고 QR 코드를 다시 스캔하십시오. ",
    noparams:"파라미터가 잘못되었습니다. 다시 이용하려면 카메라를 열고 QR 코드를 다시 스캔하세요. ",
    nodata: "데이터 없음",
    zeilitsu:"소비세"
  }
}
