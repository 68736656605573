export default {
    isString: function (obj) {
        return Object.prototype.toString.call(obj) === "[object String]";
    },
    setCookie: function (c_name,value,day,domain){
        var exdate=new Date();
        exdate.setTime(exdate.getTime()+day*24*60*60*1000);
        document.cookie=c_name+ "=" +escape(value)+((day==null) ? "" : ";expires="+exdate.toGMTString())+";path=/;domain="+domain;
    },
    getCookie: function (cname){
      var name = cname + "=";
      var ca = document.cookie.split(';');
      for(var i=0; i<ca.length; i++)
      {
        var c = ca[i].trim();
        if (c.indexOf(name)==0) return c.substring(name.length,c.length);
      }
      return "";
    },
    isFunction: function (obj) {
        return Object.prototype.toString.call(obj) === "[object Function]";
    },
    formatNum: function (strNum) {
                if(strNum<1000) {
                    return strNum;
                }
                if(!/^(\+|-)?(\d+)(\.\d+)?$/.test(strNum)) {
                    return strNum;
                }
                var a = RegExp.$1,
                    b = RegExp.$2,
                    c = RegExp.$3;
                var re = new RegExp();
                re.compile("(\\d)(\\d{3})(,|$)");
                while(re.test(b)) {
                    b = b.replace(re, "$1,$2$3");
                }
                return a + "" + b + "" + c;
            },
    formatMoney:function (num) {
     // num = num.toString().replace(/\,/g,'');
     if(num){
       var sign = (num == (num = Math.abs(num)));
       num = Math.floor(num*100+0.50000000001);
       var cents=Math.abs(num%100),c;
       if(cents==0){
           c=""
       }else if(cents<10){
           c=".0"+cents
       }else{
           c="."+cents
       }
       num = Math.floor(num/100).toString();
       for (var i = 0; i < Math.floor((num.length-(1+i))/3); i++){
           num = num.substring(0,num.length-(4*i+3))+','+num.substring(num.length-(4*i+3));
       }
       return (((sign)?"":'-') + num + c);
     }else{
       return 0;
     }
 }
}
