<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  mounted(){
    let that = this;
    that.fontSize();
    window.onresize= function(){
      that.fontSize();
    }
  },
  methods:{
    fontSize(){
    	var _html = document.getElementsByTagName("html")[0];

    	var w = document.documentElement.clientWidth;
    	var newin;
    	// console.log(w);
    	if(w>750){
    		newin=750
    	}else if(w>375 && w<750){
    		newin=w ;
    	}else if(w<=375){
    		newin=375
    	}
    	_html.style.fontSize = newin/37.5 +"px";
    }
  }
}
</script>

<style lang="less">
    @import './style/variable.less';
</style>
